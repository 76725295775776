import React, { useContext } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { graphql, PageProps } from 'gatsby'
import { Strapi__Component_Meta_Metadata, Strapi_Global } from 'gatsby-graphql'
import { Box, Typography } from '@mui/material'
import { useLocalizePage } from '@/utils/hooks'
import ThemeTypeContext from '@/components/themeTypeContext'
import { IPageContext } from '@/types/pages'
import { theme } from 'src/theme/ThemeProvider'
import ReactMarkdown from 'react-markdown'

const CookiesPolicy: React.FC<PageProps<any>> = (props) => {
  const { setTheme } = useContext(ThemeTypeContext)
  setTheme('core')
  const data = props?.data
  const pageContext = props?.pageContext as IPageContext
  const location = props?.location
  const global = data?.strapiGlobal as Strapi_Global

  const metaData = data?.strapiCookiesPolicy?.MetaData as Strapi__Component_Meta_Metadata
  const CookiePolicy = data?.strapiCookiesPolicy?.CookiesPolicy

  useLocalizePage(pageContext, location)

  return (
    <>
      <SEO metaData={metaData} global={global} />

      <Layout global={global} pageContext={{ ...pageContext, ...metaData }} theme={theme}>
        <Box
          sx={{
            '& ul ::marker': { color: theme.palette.success.main },
            '& ul': {
              paddingLeft: '20px',
            },
            '& ol': {
              paddingLeft: '20px',
            },
            fontSize: { xs: '14px', md: '16px' },
            height: 'max-content',
            marginX: { xs: '0px', md: '20px' },
            paddingX: { md: '18px', xs: '0px' },
            borderRadius: '5px',
            marginTop: { xs: '0px', md: '75px' },
            marginBottom: { xs: '66px', md: '83px' },
          }}
        >
          <Box
            sx={{
              height: '100%',
              margin: '18px',
              marginX: { xs: '5px', md: '18px' },
            }}
          >
            <Typography
              sx={{
                width: '100%',
                textAlign: 'center',
                fontSize: { xs: '30px', md: '40px' },
                lineHeight: { xs: '40px' },
                fontWeight: 'bold',
              }}
            >
              {CookiePolicy?.Title}
            </Typography>
            {CookiePolicy?.Subtitle && (
              <Typography
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  fontSize: '20px',
                  marginBottom: '32px',
                  marginTop: { xs: '10px', md: '20px' },
                }}
              >
                {CookiePolicy?.Subtitle}
              </Typography>
            )}
            <Box sx={{ marginTop: { xs: '62px', md: '66px' }, maxWidth: { md: '996px' } }}>
              <ReactMarkdown children={CookiePolicy?.Content?.data?.Content} />
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  )
}

export default CookiesPolicy

export const query = graphql`
query CookiesPolicyPage($locale: String) {
  strapiGlobal(locale: {eq: $locale}) {
    ...GlobalData
  }
  strapiCookiesPolicy(locale: {eq: $locale}) {
    MetaData {
      MetaTitle
      MetaDescription
    }
    CookiesPolicy {
      Title
      Subtitle
      Content {
        data {
          Content
        }
      }
    }
  }
}

`
